import { render, staticRenderFns } from "./OrderDetail.vue?vue&type=template&id=3c367947"
import script from "./OrderDetail.vue?vue&type=script&lang=js"
export * from "./OrderDetail.vue?vue&type=script&lang=js"
import style0 from "./OrderDetail.vue?vue&type=style&index=0&id=3c367947&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QPullToRefresh from 'quasar/src/components/pull-to-refresh/QPullToRefresh.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QPullToRefresh,QIcon,QItemSection,QItemLabel,QItem,QTooltip,QSeparator,QBtn,QList,QAvatar,QColor});

<template>
  <q-page class="accountPages orderDetail">
    <q-pull-to-refresh no-mouse @refresh="refresh">
      <div class="container" v-if="orderInfo && orderInfo.orderNumber">
        <div class="page-title hide-in-mobile">
          <div class="page-breadcrumb">
            <a
              href="javascript:void(0)"
              @click="applyBack()"
              class="no-underline"
            >
              <SvgIcon icon="back-arrow-line" />Back
            </a>
          </div>
          <h2 class="inline-block vertical-middle">
            Order Number: {{ orderInfo.orderNumber }}</h2
          >
          <div
            class="btn-cancel q-ml-sm inline-block vertical-middle"
            v-if="orderInfo.isCancelled && !isMobile"
          >
            Canceled
          </div>
          <a
            href="javascript:void(0)"
            @click="downloadOrderInvoice()"
            class="btn-print"
            v-if="!isMobile && !orderInfo.isCancelled"
          >
            <q-icon name="file_download" class="q-mr-sm" /> Download Invoice
            <!-- <SvgIcon icon="print" class="q-mr-sm" /> Print order -->
          </a>
          <div class="row" v-if="!isMobile">
            <Alert
              v-if="orderDetailAlert"
              className="col-12 col-md-6 q-mt-sm"
              :variant="orderDetailAlert.variant"
              :show="10"
            >
              <span v-html="orderDetailAlert.message" />
            </Alert>
          </div>
        </div>

        <div
          class="alert alert-danger q-mt-md"
          v-if="orderInfo.isCancelled && isMobile"
        >
          <div class="row items-center q-col-gutter-sm">
            <div class="col-auto">
              <SvgIcon icon="info" class="left-icon" />
            </div>
            <div class="col-9 col-md-10">
              <span class="text-body2 text-weight-bold text-capitalize"
                >This Order has been Canceled.</span
              >
            </div>
          </div>
        </div>

        <div
          :class="['page-content', { disable: orderInfo.isCancelled || false }]"
        >
          <!-- Order Return 1 -->

          <div class="orderTableH">
            <div
              class="orderTables curbside"
              v-if="
                orderInfo.trackOrderLineItems &&
                orderInfo.trackOrderLineItems.length
              "
            >
              <div class="orderItem orderItemhd">
                <div class="orderItemDetail">
                  <span class="icon"
                    ><SvgIcon color="primaryOnBody" :icon="orderInfo.codeName"
                  /></span>
                  <h5>{{ orderInfo.displayName }}</h5>
                </div>
                <div class="OrdetItemQty">Qty</div>
                <div class="OrdetItemTotal">Total</div>
              </div>
              <div
                v-for="(item, index) of orderInfo.trackOrderLineItems"
                :key="`${index}-${item.orderLineItemID}`"
                :class="[
                  'orderItem orderItemrow',
                  item.linkedProducts && item.linkedProducts.length > 0
                    ? 'link-products standard-shipping--method'
                    : '',
                  item.isFulfilled && item.qty === 0
                    ? 'product-not-available'
                    : '',
                ]"
              >
                <div
                  class="product-not-available-label"
                  v-if="item.isFulfilled && item.qty === 0"
                >
                  <label>Product Not Available</label></div
                >
                <div
                  :class="[
                    'orderItemDetail',
                    { blacklistedproduct: item.isBlacklisted },
                  ]"
                >
                  <div class="OrdetItemImg">
                    <box-image
                      :link="{
                        name: 'Detail',
                        params: { seoName: item.seoName },
                      }"
                      :imageSrc="item.image"
                    />
                  </div>

                  <h5>
                    <router-link
                      :to="{
                        name: 'Detail',
                        params: { seoName: item.seoName },
                      }"
                    >
                      {{ item.productName }}
                    </router-link>
                    <span
                      v-if="item.isBlacklisted"
                      class="text-negative text-body2 block q-mt-sm"
                    >
                      <q-icon name="info"></q-icon> Product No Longer on
                      Site</span
                    >
                  </h5>
                </div>
                <div class="OrdetItemQty">
                  <!-- <span class="discount" v-if="item.discount">
                    You Saved:
                    {{ item.discount | currency(false) }}
                  </span> -->
                  {{ item.qty }} x {{ item.price | currency }}
                </div>
                <div class="order-details-price">
                  <div
                    class="text-strike strike-price text-grey text-weight-regular"
                    v-html="$options.filters.currency(item.totalPrice)"
                    v-if="item.totalPrice != item.finalPrice"
                  />
                  <div
                    :class="[
                      'OrdetItemTotal',
                      { 'text-positive': item.totalPrice != item.finalPrice },
                    ]"
                    v-html="$options.filters.currency(item.finalPrice)"
                  />
                </div>
                <q-item-section
                  class="linked-product bg-primary"
                  v-if="item.linkedProducts && item.linkedProducts.length > 0"
                >
                  <q-item-label
                    :class="{ blacklistedproduct: item.isBlacklisted }"
                  >
                    <q-item
                      class="item-start"
                      v-for="(linkedProduct, index) in item.linkedProducts"
                      :key="`${linkedProduct.orderLineItemID}${index}`"
                      :class="[
                        linkedProduct.isFulfilled &&
                        linkedProduct.qty === 0 &&
                        item.qty > 0
                          ? 'product-not-available'
                          : '',
                      ]"
                    >
                      <q-item-section avatar>
                        <box-image
                          :link="
                            linkedProduct.isSellable
                              ? {
                                  name: 'Detail',
                                  params: { seoName: linkedProduct.seoName },
                                }
                              : ''
                          "
                          :imageSrc="linkedProduct.image"
                          width="40px"
                        />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="product-title ellipsis d">
                          <router-link
                            v-if="linkedProduct.isSellable"
                            :to="{
                              name: 'Detail',
                              params: { seoName: linkedProduct.seoName },
                            }"
                            v-html="linkedProduct.productName"
                          />
                          <template v-else>
                            {{ linkedProduct.productName }}
                          </template>
                          <q-tooltip max-width="180px" content-class="bg-dark">
                            {{ linkedProduct.productName }}
                          </q-tooltip>
                        </q-item-label>
                        <q-item-label caption>
                          <span
                            v-if="linkedProduct.isBlacklisted"
                            class="text-negative text-body2 block"
                          >
                            <q-icon name="info"></q-icon> Product No Longer on
                            Site</span
                          >
                        </q-item-label>
                        <!-- <q-item-label class="row no-wrap items-center" caption>
                          <q-item-label
                            class="product-qty"
                            v-html="
                              `${
                                linkedProduct.qty
                              } X ${$options.filters.currency(
                                linkedProduct.price
                              )}`
                            "
                          />
                        </q-item-label> -->
                        <!-- <q-item-label class="items-right" caption>
                          <span
                            class="discount f11 fwnormal bg q-mb-none"
                            v-if="linkedProduct.discountAmount"
                            v-html="
                              `You Saved: ${$options.filters.currency(
                                linkedProduct.discountAmount,
                                true
                              )}`
                            "
                          />
                        </q-item-label> -->
                      </q-item-section>
                      <q-item-section class="order-summery-qty">
                        <q-item-label class="row no-wrap items-center" caption>
                          <q-item-label
                            class="product-qty"
                            v-html="
                              `${
                                linkedProduct.qty
                              } X ${$options.filters.currency(
                                linkedProduct.price
                              )}`
                            "
                          />
                        </q-item-label>
                      </q-item-section>
                      <q-item-section
                        class="cartProductSide OrdetItemTotal order-summery-price"
                        side
                      >
                        <q-item-label
                          class="text-strike strike-price text-grey text-weight-regular"
                          v-html="
                            $options.filters.currency(linkedProduct.totalPrice)
                          "
                          v-if="
                            linkedProduct.totalPrice != linkedProduct.finalPrice
                          "
                        />
                        <q-item-label
                          class="product-price"
                          v-html="
                            $options.filters.currency(linkedProduct.finalPrice)
                          "
                        />
                      </q-item-section>
                      <q-item-section
                        class="product-not-available-label"
                        v-if="
                          linkedProduct.isFulfilled &&
                          linkedProduct.qty === 0 &&
                          item.qty > 0
                        "
                      >
                        <q-item-label
                          >Product Not Available</q-item-label
                        ></q-item-section
                      >
                    </q-item>
                  </q-item-label>
                </q-item-section>
              </div>
            </div>
            <br />
            <!-- not delivered -->
            <div
              class="orderTables curbside"
              v-if="
                orderInfo.notDeliveredOrderLineItems &&
                orderInfo.notDeliveredOrderLineItems.length
              "
            >
              <div class="orderItem orderItemhd">
                <div class="orderItemDetail">
                  <span class="icon">
                    <SvgIcon color="torange" icon="not-deliver" />
                  </span>
                  <h5>
                    Not Delivered ({{
                      orderInfo.notDeliveredOrderLineItems.length
                    }})
                  </h5>
                </div>
                <div class="OrdetItemQty">Qty</div>
                <div class="OrdetItemTotal">Total</div>
              </div>
              <div
                v-for="item of orderInfo.notDeliveredOrderLineItems"
                :key="item.orderLineItemID"
                :class="[
                  'orderItem orderItemrow',
                  item.linkedProducts && item.linkedProducts.length > 0
                    ? 'link-products'
                    : '',
                ]"
              >
                <div class="orderItemDetail">
                  <div class="OrdetItemImg">
                    <box-image
                      :link="{
                        name: 'Detail',
                        params: { seoName: item.seoName },
                      }"
                      :imageSrc="item.image"
                    />
                  </div>
                  <h5>
                    <router-link
                      :to="{
                        name: 'Detail',
                        params: { seoName: item.seoName },
                      }"
                    >
                      {{ item.productName }}
                    </router-link>
                  </h5>
                </div>
                <div class="OrdetItemQty">
                  {{ item.qty }} x {{ item.price | currency }}
                </div>
                <div class="OrdetItemTotal">
                  <div
                    class="text-strike strike-price text-grey text-weight-regular"
                    v-html="$options.filters.currency(item.totalPrice)"
                    v-if="item.totalPrice != item.finalPrice"
                  />
                  <div v-html="$options.filters.currency(item.finalPrice)" />
                </div>

                <q-item-section
                  class="linked-product bg-primary not-delivered--product"
                  v-if="item.linkedProducts && item.linkedProducts.length > 0"
                >
                  <q-item-label>
                    <q-item
                      class="item-start"
                      v-for="(linkedProduct, index) in item.linkedProducts"
                      :key="`${linkedProduct.orderLineItemID}${index}`"
                    >
                      <q-item-section avatar>
                        <box-image
                          :link="
                            linkedProduct.isSellable
                              ? {
                                  name: 'Detail',
                                  params: { seoName: linkedProduct.seoName },
                                }
                              : ''
                          "
                          :imageSrc="linkedProduct.image"
                          width="40px"
                        />
                      </q-item-section>
                      <q-item-section class="">
                        <q-item-label class="product-title ellipsis d">
                          <router-link
                            v-if="linkedProduct.isSellable"
                            :to="{
                              name: 'Detail',
                              params: { seoName: linkedProduct.seoName },
                            }"
                            v-html="linkedProduct.productName"
                          />
                          <template v-else>
                            {{ linkedProduct.productName }}
                          </template>
                          <q-tooltip max-width="180px" content-class="bg-dark">
                            {{ linkedProduct.productName }}
                          </q-tooltip>
                        </q-item-label>

                        <!-- <q-item-label class="items-right" caption>
                          <span
                            class="discount f11 fwnormal bg q-mb-none"
                            v-if="linkedProduct.discountAmount"
                            v-html="
                              `You Saved: ${$options.filters.currency(
                                linkedProduct.discountAmount,
                                true
                              )}`
                            "
                          />
                        </q-item-label> -->
                      </q-item-section>
                      <q-item-section class="order-summery-qty">
                        <q-item-label class="row no-wrap items-center" caption>
                          <q-item-label
                            class="product-qty"
                            v-html="
                              `${
                                linkedProduct.qty
                              } X ${$options.filters.currency(
                                linkedProduct.price
                              )}`
                            "
                          /> </q-item-label
                      ></q-item-section>
                      <q-item-section class="cartProductSide" side>
                        <q-item-label
                          class="text-strike strike-price text-grey text-weight-regular"
                          v-html="
                            $options.filters.currency(linkedProduct.totalPrice)
                          "
                          v-if="
                            linkedProduct.totalPrice !=
                              linkedProduct.finalPrice &&
                            linkedProduct.totalPrice > 0
                          "
                        />
                        <q-item-label
                          class="product-price"
                          v-html="
                            $options.filters.currency(linkedProduct.finalPrice)
                          "
                        />
                      </q-item-section>
                    </q-item>
                  </q-item-label>
                </q-item-section>
              </div>
            </div>
            <!-- return order  -->
            <div
              class="orderTables curbside"
              v-if="
                orderInfo.returnOrderLineItems &&
                orderInfo.returnOrderLineItems.length
              "
            >
              <div class="orderItem orderItemhd">
                <div class="orderItemDetail">
                  <span class="icon">
                    <SvgIcon color="torange" icon="return" />
                  </span>
                  <h5>
                    Return Items ({{ orderInfo.returnOrderLineItems.length }})
                  </h5>
                </div>
                <div class="OrdetItemQty">Qty</div>
                <div class="OrdetItemTotal">Total</div>
              </div>
              <div
                v-for="item of orderInfo.returnOrderLineItems"
                :key="item.orderLineItemID"
                :class="[
                  'orderItem orderItemrow',
                  item.linkedProducts && item.linkedProducts.length > 0
                    ? 'link-products'
                    : '',
                ]"
              >
                <div
                  :class="[
                    'orderItemDetail',
                    { blacklistedproduct: item.isBlacklisted },
                  ]"
                >
                  <div class="OrdetItemImg">
                    <box-image
                      :link="{
                        name: 'Detail',
                        params: { seoName: item.seoName },
                      }"
                      :imageSrc="item.image"
                    />
                  </div>
                  <h5>
                    <router-link
                      :to="{
                        name: 'Detail',
                        params: { seoName: item.seoName },
                      }"
                    >
                      {{ item.productName }}
                    </router-link>
                    <!-- success -->
                    <br />
                    <span
                      class="label-success"
                      v-html="`${item.returnStatusTitle}`"
                    />
                    <span
                      v-if="item.isBlacklisted"
                      class="text-negative text-body2 block q-mt-xs"
                    >
                      <q-icon name="info"></q-icon> Product No Longer on
                      Site</span
                    >
                    <!-- success -->
                    <!-- info -->
                    <!-- <br /><span class="label-info" v-html="`Updated`" /> -->
                    <!-- info -->
                    <!-- warning -->
                    <!-- <br /><span
                  class="label-warning"
                  v-html="`Item Return - Under Review`"
                /> -->
                    <!-- warning -->
                    <!-- error -->
                    <!-- <br />
                <span
                  class="label-error"
                  v-html="`Item Return - Return Declined`"
                /> -->
                    <!-- error -->
                    <!-- <div class="text-torange text-caption q-mt-sm"
                  ><strong>Reason:</strong> Item has not damaged as per
                  claim.</div
                > -->
                  </h5>
                </div>
                <div class="OrdetItemQty">
                  {{ item.returnQTY }} x {{ item.price | currency }}
                </div>
                <div class="OrdetItemTotal">
                  <div
                    class="text-strike strike-price text-grey text-weight-regular"
                    v-html="$options.filters.currency(item.totalPrice)"
                    v-if="item.totalPrice != item.finalPrice"
                  />
                  <div v-html="$options.filters.currency(item.finalPrice)" />
                </div>

                <q-item-section
                  class="linked-product bg-primary"
                  v-if="item.linkedProducts && item.linkedProducts.length > 0"
                >
                  <q-item-label
                    :class="{ blacklistedproduct: item.isBlacklisted }"
                  >
                    <q-item
                      class="item-start"
                      v-for="(linkedProduct, index) in item.linkedProducts"
                      :key="`${linkedProduct.orderLineItemID}${index}`"
                    >
                      <q-item-section avatar>
                        <box-image
                          :link="
                            linkedProduct.isSellable
                              ? {
                                  name: 'Detail',
                                  params: { seoName: linkedProduct.seoName },
                                }
                              : ''
                          "
                          :imageSrc="linkedProduct.image"
                          width="40px"
                        />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="product-title ellipsis d">
                          <router-link
                            v-if="linkedProduct.isSellable"
                            :to="{
                              name: 'Detail',
                              params: { seoName: linkedProduct.seoName },
                            }"
                            v-html="linkedProduct.productName"
                          />
                          <template v-else>
                            {{ linkedProduct.productName }}
                          </template>
                          <q-tooltip max-width="180px" content-class="bg-dark">
                            {{ linkedProduct.productName }}
                          </q-tooltip>
                        </q-item-label>
                        <q-item-label class="row no-wrap items-center" caption>
                          <q-item-label
                            class="product-qty"
                            v-html="
                              `${
                                linkedProduct.returnQTY
                              } X ${$options.filters.currency(
                                linkedProduct.price
                              )}`
                            "
                          />
                        </q-item-label>
                        <q-item-label class="row no-wrap items-center" caption>
                          <span
                            v-if="linkedProduct.isBlacklisted"
                            class="text-negative text-body2 block q-mt-xs"
                          >
                            <q-icon name="info"></q-icon> Product No Longer on
                            Site</span
                          >
                        </q-item-label>
                        <q-item-label class="items-right" caption>
                          <span
                            class="discount f11 fwnormal bg q-mb-none"
                            v-if="linkedProduct.discountAmount"
                            v-html="
                              `You Saved: ${$options.filters.currency(
                                linkedProduct.discountAmount,
                                true
                              )}`
                            "
                          />
                        </q-item-label>
                      </q-item-section>
                      <q-item-section class="cartProductSide" side>
                        <q-item-label
                          class="text-strike strike-price text-grey text-weight-regular"
                          v-html="
                            $options.filters.currency(linkedProduct.totalPrice)
                          "
                          v-if="
                            linkedProduct.totalPrice !=
                              linkedProduct.finalPrice &&
                            linkedProduct.totalPrice > 0
                          "
                        />
                        <q-item-label
                          class="product-price"
                          v-html="
                            $options.filters.currency(linkedProduct.finalPrice)
                          "
                        />
                      </q-item-section>
                    </q-item>
                  </q-item-label>
                </q-item-section>
              </div>
            </div>
          </div>
          <Substitutions
            :orderDetail="orderInfo"
            :showDeclineSubstitutionLink="false"
          />
          <div class="orderSummaryInfo">
            <div class="row q-col-gutter-md">
              <div class="col-xs-12 col-sm-6 col-md-4 o-1">
                <h5>Order Summary</h5>
                <ul class="orderSummaryITotal">
                  <li>
                    Sub Total
                    <!-- {{ orderInfo.displayName }} Total -->
                    <span>
                      {{ orderInfo.orderSummary.subTotal | currency }}
                    </span>
                  </li>

                  <template
                    v-if="
                      orderInfo.codeName == 'sd' &&
                      orderInfo.orderSummary.shippingRate !== null
                    "
                  >
                    <!-- <q-separator class="q-mt-xs" /> -->
                    <li>
                      Shipping Fee
                      <span>
                        {{
                          orderInfo.orderSummary.shippingRate == 0
                            ? 'Free'
                            : $options.filters.currency(
                                orderInfo.orderSummary.shippingRate,
                                false,
                                true
                              )
                        }}</span
                      >
                    </li>
                  </template>
                  <template v-if="orderInfo.orderSummary.tipAmount">
                    <!-- <q-separator /> -->
                    <li>
                      Tip
                      <span>
                        {{
                          orderInfo.orderSummary.tipAmount
                            | currency(false, true)
                        }}
                      </span>
                    </li>
                  </template>
                  <template
                    v-if="
                      orderInfo.orderSummary.additionalCharges &&
                      orderInfo.orderSummary.additionalCharges.length
                    "
                  >
                    <!-- <q-separator /> -->
                    <li
                      v-for="additionalCharge of orderInfo.orderSummary
                        .additionalCharges"
                      :key="additionalCharge.chargeName"
                    >
                      {{ additionalCharge.chargeName }}
                      <span>
                        {{
                          additionalCharge.chargeAmount | currency(false, true)
                        }}
                      </span>
                    </li>
                  </template>
                  <template v-if="orderInfo.orderSummary.discount">
                    <!-- <q-separator /> -->
                    <li>
                      Discount Applied
                      <span>
                        {{ orderInfo.orderSummary.discount | currency(true) }}
                      </span>
                    </li>
                  </template>

                  <template v-if="orderInfo.orderSummary.buydown">
                    <!-- <q-separator /> -->
                    <li>
                      Other Discount
                      <span>
                        - {{ orderInfo.orderSummary.buydown | currency }}
                      </span>
                    </li>
                  </template>

                  <li v-if="orderInfo.orderSummary.appliedLnsDollars">
                    Loyalty Dollars Applied
                    <span>
                      -
                      {{ orderInfo.orderSummary.appliedLnsDollars | currency }}
                    </span>
                  </li>

                  <!-- <li>
                    <strong>Sub Total </strong>
                    <span>
                      <strong>
                        {{
                          (orderInfo.orderSummary.cartTotal -
                            (orderInfo.orderSummary &&
                            orderInfo.orderSummary.taxes
                              ? orderInfo.orderSummary.taxes
                              : 0) -
                            (orderInfo.orderSummary &&
                            orderInfo.orderSummary.appliedLnsDollars
                              ? orderInfo.orderSummary.appliedLnsDollars
                              : 0))
                            | currency
                        }}
                      </strong>
                    </span>
                  </li> -->

                  <template v-if="orderInfo.orderSummary.otherTax">
                    <!-- <q-separator /> -->
                    <li>
                      Tax
                      <span>
                        {{
                          orderInfo.orderSummary.otherTax
                            | currency(false, true)
                        }}
                      </span>
                    </li>
                  </template>
                  <template v-if="orderInfo.orderSummary.occupationalTax">
                    <!-- <q-separator /> -->
                    <li>
                      Occupational Tax
                      <span>
                        {{
                          orderInfo.orderSummary.occupationalTax
                            | currency(false, true)
                        }}
                      </span>
                    </li>
                  </template>

                  <template
                    v-if="
                      orderInfo &&
                      orderInfo.codeName &&
                      orderInfo.codeName == 'scd' &&
                      orderInfo.orderSummary.shippingRate
                    "
                  >
                    <!-- <q-separator /> -->
                    <li>
                      Delivery Fee
                      <span>
                        {{
                          orderInfo.orderSummary.shippingRate
                            | currency(false, true)
                        }}
                      </span>
                    </li>
                  </template>

                  <template
                    v-if="
                      orderInfo.appliedLnsCoupons &&
                      orderInfo.appliedLnsCoupons.length
                    "
                  >
                    <strong
                      ><p class="q-mt-sm applied-coupons"
                        >Applied Loyalty Coupon(s)</p
                      ></strong
                    >
                    <q-separator />
                    <li
                      v-for="appliedLnsCoupon in orderInfo.appliedLnsCoupons"
                      :key="appliedLnsCoupon.id"
                      style="padding-right: 0px !important"
                    >
                      <span> - {{ appliedLnsCoupon.amount | currency }} </span>
                      <div>
                        {{ appliedCouponName(appliedLnsCoupon.name) }}
                        <q-tooltip max-width="180px" content-class="bg-dark">
                          {{ appliedLnsCoupon.name }}
                        </q-tooltip>
                      </div>
                    </li>
                  </template>
                  <template
                    v-if="
                      orderInfo.appliedDiscountCoupons &&
                      orderInfo.appliedDiscountCoupons.length
                    "
                  >
                    <strong
                      ><p class="q-mt-sm applied-coupons"
                        >Applied Discount Coupons</p
                      ></strong
                    >
                    <q-separator />
                    <li
                      v-for="appliedDiscountCoupon in orderInfo.appliedDiscountCoupons"
                      :key="appliedDiscountCoupon.id"
                      style="padding-right: 0px !important"
                    >
                      <span>
                        {{ appliedDiscountCoupon.amount | currency }}
                      </span>
                      <div>
                        {{ appliedDiscountCoupon.couponCode }}
                        <q-tooltip max-width="180px" content-class="bg-dark">
                          {{ appliedDiscountCoupon.couponCode }}
                        </q-tooltip>
                      </div>
                    </li>
                  </template>
                  <!-- <template v-if="orderInfo.orderSummary.appliedLnsDollars">
                    <q-separator class="q-mt-xs" />
                    <li class="eredeem">
                      <div class="text-left ">
                        <span><strong>Loyalty Dollars Applied</strong></span>
                        <strong class="float-right ">
                          <span class="vertical-middle	">
                            -
                            {{
                              orderInfo.orderSummary.appliedLnsDollars
                                | currency
                            }}
                          </span>
                        </strong>
                      </div>
                    </li>
                  </template> -->
                  <q-separator class="q-mt-xs" />

                  <li class="total">
                    Order Total
                    <span>
                      {{ orderInfo.orderSummary.finalPayAmount | currency }}
                    </span>
                  </li>
                </ul>
              </div>
              <div
                class="col-xs-12 col-sm-6 col-md-3 o-2"
                v-if="orderInfo.pickupStore"
              >
                <h5>Pickup Store</h5>
                <p>
                  {{ orderInfo.pickupStore.name }}
                  <br />
                  <span
                    v-html="
                      $options.filters.formatAddress(orderInfo.pickupStore)
                    "
                  />
                </p>
              </div>
              <div
                class="col-xs-12 col-sm-6 col-md-3 o-2"
                v-if="orderInfo.shippingAddress"
              >
                <h5>Delivered To</h5>
                <p>
                  {{
                    orderInfo.shippingAddress.firstName +
                    ' ' +
                    orderInfo.shippingAddress.lastName
                  }}
                  <span
                    v-if="orderInfo.shippingAddress.isDefault"
                    class="default q-ml-sm"
                  >
                    Default
                  </span>
                  <br />
                  <span
                    v-html="
                      $options.filters.formatAddress(orderInfo.shippingAddress)
                    "
                  />
                </p>
              </div>
              <div
                class="col-xs-12 col-sm-6 col-md-3 o-2"
                v-if="orderInfo.pickupType && orderInfo.codeName == 'sp'"
              >
                <h5>Pickup Method</h5>
                <p>
                  {{ orderInfo.pickupType == 1 ? 'In Store' : 'Curbside' }}
                </p>
              </div>
              <div
                class="col-xs-12 col-sm-6 col-md-3 o-3"
                v-if="orderInfo.pickupPerson"
              >
                <h5>Pickup Person</h5>
                <p>
                  Name: {{ orderInfo.pickupPerson.pickUpByName1 }}<br />
                  Email:
                  {{
                    orderInfo.pickupPerson.pickUpByEmail1
                      ? orderInfo.pickupPerson.pickUpByEmail1
                      : 'NA'
                  }}
                  <!-- Phone:
                  {{
                    orderInfo.pickupPerson.pickUpByPhone1
                      ? maskPhoneNumber(orderInfo.pickupPerson.pickUpByPhone1)
                      : 'NA'
                  }} -->
                </p>
                <template v-if="orderInfo.pickupPerson.pickUpByName2">
                  <br />
                  <h5>Alternate Pickup</h5>
                  <p>
                    Name: {{ orderInfo.pickupPerson.pickUpByName2 }}<br />
                    <template v-if="orderInfo.pickupPerson.pickUpByEmail2">
                      Email:
                      {{ orderInfo.pickupPerson?.pickUpByEmail2 || 'NA' }}<br />
                    </template>
                    <template v-if="orderInfo.pickupPerson.pickUpByPhone2">
                      Phone:
                      {{
                        orderInfo.pickupPerson.pickUpByPhone2
                          ? maskPhoneNumber(
                              orderInfo.pickupPerson.pickUpByPhone2
                            )
                          : 'NA'
                      }}
                    </template>
                  </p>
                </template>
              </div>
              <div
                class="col-xs-12 col-sm-6 col-md-3 o-4"
                v-if="orderInfo.isPayAtStore || orderInfo.orderPaymentDetail"
              >
                <h5>Payment Method</h5>
                <p v-if="orderInfo.isPayAtStore">
                  <SvgIcon size="44px" icon="pay-at-store" class="q-mr-sm" />
                  Pay at store
                </p>
                <p v-else-if="orderInfo.orderPaymentDetail">
                  <span class="text-weight-bold"> Credit or debit card</span
                  ><br />
                  <!-- <SvgIcon size="24px" icon="card" class="q-mr-sm" /> -->
                  <span :class="`paymentIcon paymentIcon-left`">
                    <img
                      :src="`images/payment/${
                        getCardType(orderInfo.orderPaymentDetail.cardType).logo
                      }`"
                      :title="
                        getCardType(orderInfo.orderPaymentDetail.cardType).title
                      "
                    />
                  </span>

                  {{ orderInfo.orderPaymentDetail.cardHolderName }}
                  <span
                    v-if="orderInfo.orderPaymentDetail.isDefault"
                    class="default q-ml-sm"
                  >
                    Default </span
                  ><br />
                  xxxx-xxxx-xxxx-{{
                    orderInfo.orderPaymentDetail.cardLast4Digits
                  }}<br />
                  Expires :
                  {{ orderInfo.orderPaymentDetail.cardExpiry }}
                </p>
              </div>
            </div>
          </div>
          <!-- Start Return Info Box -->
          <div
            v-if="orderInfo.isReturnPossible && false"
            class="startReturnInfo"
          >
            <div class="row q-col-gutter-sm items-center">
              <div class="col-12 col-sm-12 col-md-4">
                <h3>Have an item issue?</h3>
              </div>
              <div class="col-12 col-sm-6 col-md-6">
                <p>
                  If you have any item issues or aren't satisfied with your
                  substitutions, you can always start a return.</p
                >
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <q-btn
                  :to="{
                    name: 'OrderStartReturn',
                    params: { orderId: orderId },
                  }"
                  unelevated
                  color="tertiary"
                  label="Start a return!"
                  class="full-width"
                />
              </div>
            </div>
          </div>
          <!-- Start Return Info Box -->
          <!-- Order Return 1 -->
        </div>
      </div>
      <OrderDetailSkeleton v-else />
    </q-pull-to-refresh>
  </q-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { maskPhoneNumber, appliedCouponName, getCardType } from 'src/utils'
import Substitutions from 'components/TrackOrder/Substitutions'

export default {
  name: 'OrderDetail',
  props: {
    orderId: {
      type: String,
      default: '',
    },
  },
  meta() {
    return {
      title: 'Order Detail',
      ...this.commonMeta,
    }
  },
  components: {
    OrderDetailSkeleton: () => import('components/OrderDetailSkeleton'),
    Substitutions,
  },
  /* preFetch({ store, currentRoute, redirect }) {
    let { orderId } = currentRoute.params
    store.commit('order/DELETE_ORDER_DETAIL', orderId)
    let promise = store
      .dispatch('order/viewOrderDetail', orderId)
      .then((response) => {
        if (!response)
          redirect({
            name: 'Orders',
          })
      })

    if (process.env.SERVER) return promise
  }, */
  computed: {
    ...mapGetters('order', ['orderInfoById', 'alertData']),
    orderDetailAlert() {
      return this.alertData('orderDetailAlert')
    },
    orderInfo() {
      return this.orderInfoById(this.orderId)
    },
  },
  methods: {
    maskPhoneNumber: maskPhoneNumber,
    appliedCouponName: appliedCouponName,
    getCardType: getCardType,
    ...mapActions('order', ['generateInvoice']),
    refresh(done) {
      this.$store
        .dispatch('order/viewOrderDetail', this.orderId)
        .then((response) => {
          if (!response)
            this.$router
              .push({
                name: 'Orders',
              })
              .catch(() => {})
        })
        .finally(() => {
          done()
        })
    },
    downloadOrderInvoice() {
      if (this.$route.params.orderId) {
        this.generateInvoice({
          orderId: this.$route.params.orderId,
          showLoader: true,
          invoiceErrorAlertKey: 'orderDetailAlert',
        })
          .then((response) => {
            if (response?.data && response.data instanceof Blob) {
              const file = new Blob([response.data], {
                type: 'application/pdf',
              })
              const fileURL = window.URL.createObjectURL(file)
              const link = document.createElement('a')
              link.href = fileURL
              link.download = 'Order-' + this.orderInfo.orderNumber + '.pdf'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
  destroyed() {
    if (this.orderDetailAlert)
      this.$store.commit('order/SET_ORDER_ALERT', {
        alertKey: 'orderDetailAlert',
        alertData: null,
      })
  },
  mounted() {
    // prefetch code
    let { orderId } = this.$route.params
    this.$store.commit('order/DELETE_ORDER_DETAIL', orderId)
    this.$store.dispatch('order/viewOrderDetail', orderId).then((response) => {
      if (!response)
        this.$router.replace({
          name: 'Orders',
        })
    })
    // prefetch code
  },
}
</script>
<style lang="scss">
.btn-cancel {
  font-size: 14px;
  color: #ec7a7a;
  text-transform: uppercase;
  text-decoration: none;
  &.text-capitalize {
    text-transform: capitalize;
  }
  i {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    vertical-align: bottom;
    margin-right: 8px;
    background: #f9d7d7;
    color: #ec7a7a;
    svg {
      width: 7px;
      height: 7px;
    }
  }
}

.orderDetail {
  .orderTableH {
    // min-height: 360px;
  }
  /* Substitutions Styles */
  .actions {
    text-align: right;
    .q-btn {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    }
    .q-btn__wrapper {
      padding: 9px 18px;
    }
    @media (max-width: 767px) {
      text-align: center;
      .q-btn {
        margin: 5px;
        width: 100%;
        max-width: 300px;
      }
    }
  }
  .orderSingleLabel {
    padding: 10px 0;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #0f141a;
    border-bottom: 1px dashed #cccccc;
  }
  .q-list {
    margin-top: 10px;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      padding-bottom: 15px;
    }
    .q-item {
      border-left: 3px solid #fff;
    }
    &.substitution {
      background: rgba(42, 157, 204, 0.1);
      margin-top: 2px;
      margin-bottom: 2px;
      margin-left: 3px;
      //border-left: 4px solid rgba(42, 157, 204, 0.8);\

      .q-item {
        padding-top: 10px;
        padding-bottom: 10px;
        border-left: none;
        //padding-left: 15px;
        &.link-products {
          padding-left: 10px;
          border-left: 3px solid rgba(42, 157, 204, 0.5);
          @media (max-width: 767px) {
            justify-content: end;
          }
        }
        &.linked-product {
          padding-left: 10px;
          padding-right: 0;
          border-top: 2px solid rgba(255, 255, 255, 1);
          border-left: 3px dashed rgba(42, 157, 204, 0.5);
          background: #fff;
        }
        /*.orderItemPrice {
                color: #008a03;
              }*/
        &.disable {
          opacity: 0.7;
          pointer-events: none;
          img {
            filter: grayscale(100%);
          }
          .orderItemPrice {
            color: #888888;
          }
        }
      }
      &.decline {
        background: rgba(236, 122, 122, 0.1);
        .q-item {
          border-left-color: rgba(236, 122, 122, 0.8);
        }
        .q-item__section--main,
        .orderItemPrice {
          /*text-decoration: line-through;*/
        }
        .linked-product {
          background: none;
        }
      }
    }
  }
  .q-item {
    padding: 15px 5px;
    &.link-products {
      padding: 0;
      margin: 0;
      .linked-product {
        padding-left: 10px;
        padding-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
        border-left: 3px dashed rgba(42, 157, 204, 0.5);
        /*background: rgba(42, 157, 204, 0.1) !important;*/
      }
      .product-not-available {
        padding-bottom: 30px;
      }
    }
    &.product-not-available {
      padding-bottom: 30px;
      border: 1px dashed #bdbdbd;
      border-radius: 5px;
      padding-left: 0;
      > *:not(.product-not-available-label) {
        opacity: 0.4;
        pointer-events: none;
      }
      .product-not-available-label {
        bottom: 3px;
        left: 5px;
        background-color: #e5e5e5;
        border-radius: 10px;
        padding: 2px 8px;
        color: $negative;
        position: absolute;
        opacity: 0.8;
        font-size: 14px;
        width: auto;
      }
    }
  }
  .q-list:not(.singleItem) {
    display: flex;
    flex-wrap: wrap;
    .q-item__section--avatar {
      min-width: 50px;
      padding-right: 0;
    }
  }
  .q-item__section--avatar {
    //min-width: 70px;
  }
  .q-item__section--side {
    align-items: end;
    padding-right: 10px;
    .caption {
      font-size: 14px;
      color: #888888;
      &.decline {
        color: #ec7a7a;
      }
      a {
        font-size: 14px;
        color: #ec7a7a;
        text-decoration: underline;
      }
    }
    &.trackPrice {
      align-items: flex-end;
    }
  }
  .q-avatar {
    height: 1.3em;
    width: 1.3em;
    .q-avatar__content {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    }
    a,
    .box-img {
      padding: 5px;

      width: 50px;
      text-align: center;
      text-decoration: none;
    }
  }
  .last {
    .q-avatar a {
      height: 52px;
      background: $primary;
      background: var(--q-color-primary) !important;
      border-color: $primary;
      border-color: var(--q-color-primary) !important;
      border-color: var(--q-color-primary) !important;
      color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        strong {
          font-size: 18px;
        }
      }
    }
  }
  .q-item__label a {
    color: #333b3e;
    font-size: 16px;
    text-decoration: none;
    padding: 5px;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
  .text-caption {
    font-size: 15px;
    span.discount {
      margin-bottom: 5px;
      display: block;
      font-size: 12px;
      color: #008a03;
    }
    strong {
      font-size: 15px;
      font-weight: normal;
    }
  }
  .orderItemPrice {
    font-size: 18px;
    font-weight: bold;
    color: $tertiary;
  }
  .trackPrice {
    flex: 0 0 15%;
  }
  @media (min-width: 1024px) {
    .trackPrice {
      flex: 0 0 10%;
    }
  }
  @media (max-width: 1023px) {
    .q-item__section--main {
      width: 75%;
    }
  }
  @media (max-width: 767px) {
    .q-item {
      flex-wrap: wrap;
    }
    .q-item__section--avatar {
      flex: 1 1 56px;
    }
    .q-item__section--main {
      flex: 10000 1 calc(100% - 70px);
    }
    .trackQty {
      flex: 0 0 65%;
      padding-left: 70px;
    }
    .trackPrice {
      flex: 0 0 35%;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 0;
    }
  }
  /* Substitutions Styles */
  .linked-product {
    .q-item {
      padding: 5px;
    }
    .cartProductSide {
      min-width: 80px;
      @media (max-width: 1023px) {
        display: flex;
        float: none;
      }
      @media (max-width: 599px) {
        text-align: right;
        padding-right: 0;
      }
    }
  }
}
.orderTables {
  /*min-height: 360px;*/
  min-height: auto;
  .orderItem {
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    font-size: 16px;
    @media (max-width: 599px) {
      flex-wrap: wrap;
    }
    @media (min-width: 1024px) {
      align-items: center;
    }

    &.orderItemhd {
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.25);
      h5,
      .OrdetItemQty,
      .OrdetItemTotal {
        font-size: 18px !important;
        font-weight: bold !important;
        color: rgba(49, 65, 84, 0.5) !important;
        @media (max-width: 1023px) {
          font-size: 16px !important;
        }
      }
      @media (max-width: 599px) {
        .OrdetItemQty {
          display: none;
        }
        .OrdetItemTotal {
          padding: 10px 0;
        }
      }
    }
    .orderItemDetail {
      flex: 10000 0 0%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .OrdetItemImg {
        flex: 0 0 50px;
        margin-right: 8px;
      }
      .icon,
      .checkbox {
        flex: 0 0 45px;
        text-align: center;
        font-size: 24px;
        line-height: 35px;
      }
      h5 {
        margin: 0;
        padding: 10px 0;
        font-size: 16px;
        line-height: normal;
        font-weight: normal;
        color: $tertiary;
        a {
          color: $tertiary;
          text-decoration: none;
          &:hover {
            color: $primary;
            color: var(--q-color-primary) !important;
            text-decoration: underline;
          }
        }
      }
      @media (min-width: 1024px) {
        align-items: center;
        padding-right: 12px;
      }
    }
    .OrdetItemQty {
      min-width: 120px;
      padding: 10px 0;
      color: #888888;
      @media (max-width: 599px) {
        order: 4;
        padding-left: 58px;
        padding-top: 0;
        min-width: 100%;
      }
    }
    .OrdetItemTotal {
      min-width: 80px;
      padding: 10px 0;
      font-weight: bold;
      color: $tertiary;
      text-align: right;
      @media (max-width: 599px) {
        text-align: right;
        padding: 0;
      }
    }
    &.orderItemrow {
      .orderItemDetail {
        h5 {
          padding-top: 3px;
        }
      }
    }
    &.product-not-available {
      padding-bottom: 30px;
      border: 1px dashed #bdbdbd;
      position: relative;
      border-radius: 5px;

      > *:not(.product-not-available-label) {
        opacity: 0.4;
        pointer-events: none;
      }
      .product-not-available-label {
        bottom: 3px;
        left: 5px;
        background-color: #e5e5e5;
        border-radius: 10px;
        padding: 2px 8px;
        color: $negative;
        position: absolute;
        opacity: 0.8;
        font-size: 14px;
      }
    }
    &.link-products {
      .linked-product {
        @media (max-width: 599px) {
          order: 5;
        }
      }
    }
  }
  .order-summery-qty {
    max-width: 120px;
    margin-left: 0;
    min-width: 120px;
    .text-caption {
      font-size: 16px;
    }
  }
  .linked-product {
    .q-item {
      padding-right: 0;
      .q-item__section--avatar {
        min-width: 53px;
        width: 60px;
      }
      @media (max-width: 1023px) {
        .q-item__section--avatar {
          min-width: 50px;
          width: 50px;
          margin-right: 10px;
        }
      }
      @media (max-width: 600px) {
        padding-left: 56px !important;
        &.product-not-available {
          padding-bottom: 60px;
          // padding-left: 0 !important;
          .order-summery-qty {
            bottom: 30px;
          }
          .q-item__section--side {
            min-width: 53px;
            width: 53px;
            // position: relative;
          }
        }
      }
    }
    .product-title {
      a {
        color: $tertiary;
        text-decoration: none;
        &:hover {
          color: $primary;
          color: var(--q-color-primary) !important;
          text-decoration: underline;
        }
      }
    }
    .order-summery-qty {
      @media (max-width: 1024px) {
        .q-item__label {
          display: block;
          text-align: left;
        }
      }
      @media (max-width: 600px) {
        position: absolute;
        bottom: 10px;
        left: 57px;
        text-align: left;
        .q-item__label {
          text-align: left;
        }
      }
    }
    .OrdetItemTotal {
      @media (max-width: 1024px) {
        float: right;
      }
    }
  }
  .order-details-price {
    text-align: right;
    .strike-price {
      & + .OrdetItemTotal {
        padding-top: 0;
      }
    }
  }
}
/* Order Summary Info */
.orderSummaryInfo {
  margin-top: 20px;
  background: #fafafa;
  border: 1px solid #cccccc;
  padding: 20px;
  border-radius: 5px;
  .o-1 {
    order: 1;
  }
  .o-2 {
    order: 2;
  }
  .o-3 {
    order: 3;
  }
  .o-4 {
    order: 4;
  }
  @media (min-width: 600px) {
    .o-2 {
      order: 1;
    }
    .o-1 {
      order: 2;
    }
  }
  @media (min-width: 1024px) {
    .o-1 {
      order: 5;
      margin-left: auto;
    }
  }
  h5 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: $tertiary;
  }
  p {
    margin: 0;
    font-size: 15px;
    line-height: 24px;
    color: #888888;
    .default {
      background: #91bf5b;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      padding: 3px 10px;
      border-radius: 12px;
    }
  }
}
ul.orderSummaryITotal {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 6px 0;
    padding-right: 90px;
    font-size: 15px;
    font-weight: normal;
    color: #888888;
    position: relative;
    > span {
      font-weight: bold;
      width: 90px;
      position: absolute;
      right: 0;
      top: 6px;
      text-align: right;
    }
    &.total {
      padding: 10px 0 5px;
      font-weight: 700;
      color: #314154;
      span {
        top: 10px;
        font-weight: 700;
      }
    }
  }
}
/* Order Summary Info */
/* Start Return Info */
.startReturnInfo {
  margin-top: 30px;
  padding: 20px 25px;
  background: #fff;
  color: #888888;
  border: 1px solid $primary;
  border: 1px solid var(--q-color-primary) !important;
  border-radius: 8px;
  h3 {
    margin: 0;
    padding: 10px 0;
    font-size: 24px;
    line-height: normal;
    color: $primary;
    color: var(--q-color-primary) !important;
  }
  p {
    margin: 0;
    padding: 10px 0;
    font-size: 16px;
  }
}
.linked-product {
  .q-item {
    @media (max-width: 991px) {
      padding-left: 60px !important;
      .q-item__section--main {
        width: 75%;
      }
    }
  }
}
.not-delivered--product.linked-product,
.standard-shipping--method {
  .q-item.q-item-type.row.no-wrap.item-start {
    @media (max-width: 1023px) {
      flex: 10000 0 0%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  @media (max-width: 767px) {
    flex: 1 0 100% !important;
  }
  .q-item__section--main {
    @media (max-width: 767px) {
      flex: inherit;
    }
  }
}
</style>
